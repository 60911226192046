<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-04-16 14:03:15
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-04-16 14:52:23
 * @FilePath: \envision-webe:\sirun\performance\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <BasicPage />
  </div>
</template>

<script>
import BasicPage from "./components/BasicPage.vue";

export default {
  name: "App",
  components: {
    BasicPage,
  },
};
</script>

<style>
.body {
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.app {
  font-family: "Arial", sans-serif;
}
.div-body {
  padding-top: 30px;
  display: none;
}

.active {
  display: block;
}
</style>
